import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';

export default function Meta({ meta }) {
  const data = useStaticQuery(graphql`
    query MetaQuery {
      site {
        siteMetadata {
          title
          description
          titleTemplate
          image
          url
        }
      }
    }
  `);

  const internalMetadata = data?.site?.siteMetadata;

  const seo = {
    title: meta.title || internalMetadata?.title,
    description: meta.description || internalMetadata?.description,
    //   || (defaultMetadata?.default_desription
    //     ? RichText.asText(defaultMetadata.default_desription as RichTextBlock[])
    //     : undefined
    //   )
    //   || internalMetadata?.description,
    image: meta.image || `${internalMetadata?.url}/${internalMetadata?.image}`,
    // url: `${internalMetadata?.url}${pathname || '/'}`,
    url: `${internalMetadata?.url}`,
    titleTemplate: internalMetadata?.titleTemplate,
  };

  const generalCard = {
    url: seo.url,
    title: seo.title || '',
    description: seo.description || '',
    image: seo.image || '',
    imageAlt: seo.title || '',
  };

  //   const twitterCard = social?.twitterCard?.primary && {
  //     title: social.twitterCard.primary.title?.text || '',
  //     description: social.twitterCard.primary.description?.text || '',
  //     image: social.twitterCard.primary.image?.url || '',
  //     imageAlt: social.twitterCard.primary.image?.alt || '',
  //     type: 'card_type' in social.twitterCard.primary && social.twitterCard.primary.card_type === 'Standard Summary Card'
  //       ? 'summary'
  //       : 'summary_large_image',
  //     handle: 'twitter_handle' in social.twitterCard.primary
  //       ? social.twitterCard.primary.twitter_handle || ''
  //       : '',
  //   };

  return (
    <>
      <Helmet
        title={meta.title || 'Liminal'}
        titleTemplate={seo.titleTemplate}
        htmlAttributes={{ lang: 'en' }}
      >
        <meta name='description' content={seo.description} />
        <meta property='og:url' content={generalCard.url} />
        <meta property='og:title' content={generalCard.title} />
        <meta property='og:description' content={generalCard.description} />
        <meta property='og:image' content={generalCard.image} />
        <meta property='og:image:alt' content={generalCard.imageAlt} />

        {/* {(article ? true : null) && (
          <meta property="og:type" content="article" />
        )}
        {twitterCard?.type && <meta name="twitter:card" content={twitterCard.type} />}
        {twitterCard?.handle && <meta name="twitter:site" content={twitterCard.handle} />}
        {twitterCard?.title && <meta name="twitter:title" content={twitterCard.title} />}
        {twitterCard?.description && <meta name="twitter:description" content={twitterCard.description} />}
        {twitterCard?.image && <meta name="twitter:image" content={twitterCard.image} />}
        {twitterCard?.imageAlt && <meta name="twitter:image:alt" content={twitterCard.imageAlt} />} */}
      </Helmet>
    </>
  );
}
